import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FleetReport from './Pages/FleetReport';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { theme } from '@chakra-ui/react';
import ProtectedWrapper from './components/ProtectedWrapper';
import DashboardLayout from './components/Layout/dashboardLayout';
import TruckSummary from './Pages/TruckSummary';
import TruckSummary2 from './Pages/TruckSummary2';
import FleetReportAdmin from './Pages/FleetReportAdmin';
import { useAtom } from 'jotai';
import { userAtom } from './atoms';
import Fleet from './Pages/Fleet';

function DashboardRoutes() {
  const [user] = useAtom(userAtom);
  const isAdmin = user?.user_permission === 'dd_admin';

  return (
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        {isAdmin && (
          <Route path="admin/fleet" element={<FleetReportAdmin />} />
        )}
          <Route path="fleet" element={<Fleet/>} />
          <Route path="truck-summary" element={<TruckSummary />} />
          <Route path="truck-summary2" element={<TruckSummary2 />} />
      </Routes>
    </DashboardLayout>
  );
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/auth/sign-in" element={<Login />} />
          <Route path="/dashboard/*" element={<ProtectedWrapper element={<DashboardRoutes />} />} />
          <Route path="/" element={<ProtectedWrapper element={<DashboardRoutes />} />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}


export default App;
