'use client'

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  Image,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Input, InputGroup, InputLeftElement, useToast,

} from '@chakra-ui/react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  MinusIcon, SearchIcon,
} from '@chakra-ui/icons';

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiCircle,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import grab from './../../utils/grab';
import { useLocation } from 'react-router-dom';
import DigitalDispatcherLogo from './../../assets/img/DigitalDispatcherLogoWhite.png';
import { LinkItems } from '../../routes';
const SidebarContent = ({ onClose, ...rest }) => {
  const [user] = useAtom(userAtom);

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('gray.700', 'gray.900')}
      borderRight="1px"
      m={1}
      borderRadius={7}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: '98%', md: 60 }}
      pos="fixed"
      h="full"

      {...rest}>
      <Flex h="100px" alignItems="center" px={7} justifyContent="space-between" >
        <Image
          src={DigitalDispatcherLogo}
          alt="Digital Dispatcher"
        />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} color={'white'}/>
      </Flex>
      {LinkItems.map((link) => {
        let hasUserPermission = false;
        let hasLocationPermission = false;
        if (link.user_permissions){
          hasUserPermission = link.user_permissions.some(permission => user.user_permission.includes(permission));
          hasLocationPermission = link.location_permissions.some(permission => user.locationPermission.includes(permission));
        } else {
          hasUserPermission = true
          hasLocationPermission = true
        }



        if (hasUserPermission && hasLocationPermission) {
          return link.subItems ? (
            <NavItemAccordion key={link.name} icon={link.icon} subItems={link.subItems}>
              {link.name}
            </NavItemAccordion>
          ) : (
            <NavItem key={link.name} icon={link.icon} url={link.url}>
              {link.name}
            </NavItem>
          );
        }
        return null;
      })}
    </Box>
  )
}

const NavSubItem = ({ icon, children, url, ...rest }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === url;
  
    return (
      <Box
        borderTopWidth={1}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
        color={isActive ? 'blue.400' : 'white'}
        fontWeight={isActive ? 'bold' : 'inherit'}
        _hover={{
          bg: 'gray.600',
          color: 'white',
        }}
        onClick={() => navigate(url)} // Use navigate with the url prop
      >
        <Flex
          align="center"
          p="4"
          mx="2"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize={5}
              _groupHover={{
                color: 'blue.400',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    )
  }

const NavItemAccordion = ({ icon, children, subItems, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [user] = useAtom(userAtom);
    const handleToggle = () => setIsOpen(!isOpen);
    return (
      <Accordion allowToggle onChange={handleToggle} >
        <AccordionItem>
          <AccordionButton
            borderTopWidth={2}
            borderBottomWidth={0}
            mb={isOpen ? '-2' : '0'}
            align="center"
            py={4}
            role="group"
            cursor="pointer"
            _hover={{
              bg: 'gray.600',
              color: 'white',
            }}
            color={'white'}
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                ml="4"
                fontSize="16"
                _groupHover={{
                  color: 'white',
                }}
                as={isOpen ? ChevronUpIcon : ChevronDownIcon}
              />
            )}
            {children}
          </AccordionButton>
          <AccordionPanel>
            {subItems.map(child => {
              let hasUserPermission = false;
              let hasLocationPermission = false;
              if (child.user_permissions){
                hasUserPermission = child.user_permissions.some(permission => user.user_permission.includes(permission));
                hasLocationPermission = child.location_permissions.some(permission => user.locationPermission.includes(permission));
              } else {
                hasUserPermission = true;
                hasLocationPermission = true;
              }

              if (hasUserPermission && hasLocationPermission) {
                return (
                  <NavSubItem key={child.name} icon={FiCircle} url={child.url}>
                    {child.name}
                  </NavSubItem>
                );
              }
              return null;
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

const NavItem = ({ icon, children, url, ...rest }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === url;
  
    return (
      <Box
        borderTopWidth={2}
        style={{ textDecoration: 'none' }}
        color={isActive ? 'blue.400' : 'white'}
        fontWeight={isActive ? 'bold' : 'inherit'}
        _focus={{ boxShadow: 'none' }}
        _hover={{
            bg: 'gray.600',
            color: 'white',
          }}
        
        onClick={() => navigate(url)} // Use navigate with the url prop
      >
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    )
  }

const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const toast = useToast();


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(() => {
        fetchLocations();
      }, 1000)
    );
  };
  const handleLogout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    navigate('/auth/sign-in');
  };

  const fetchUserData = async () => {
    try {
      const response = await grab.get('/user');
      setUser(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/auth/sign-in');
      }
    }
  };

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const response = await grab.get(`/user/locations?search=${searchTerm}`);
      setLocations(response.data.locations);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchTerm !== '') {
      fetchLocations();
    }
  }, [searchTerm]);

  const handleChangeLocation = async (location) => {
    try {
      const response = await grab.post(`/user/locations/change/${location.id}`);
      Cookies.set('accessToken', response.data.token);
      await fetchUserData();
      toast({
        title: "Location Changed",
        description: `You have successfully changed to ${location.name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />



      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" onClick={fetchLocations} _focus={{ boxShadow: 'none' }}>
              <HStack mr={5}>
                <VStack
                  display={{ base: 'flex', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  mr="1">
                  <Text fontSize="sm">{user.locationName}</Text>

                </VStack>
                <Box display={{ base: 'flex', md: 'flex' }}>
                  <FiChevronDown />
                </Box>

              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              minH={'30vh'}
              minW={'20vw'}
              p={2}
            >

              {loading ? (
                <MenuItem>Loading...</MenuItem>
              ) : (
                locations.map(location => (
                  <MenuItem key={location.id} onClick={() => handleChangeLocation(location)}>
                    <VStack
                      display={{ base: 'flex', md: 'flex' }}
                      alignItems="flex-start"
                      spacing="1px"
                      mr="1">
                      <Text fontSize="sm">{location.name}</Text>
                      <Text fontSize="xs" color="gray.600">
                        {location.location_permission}
                      </Text>
                    </VStack>
                  </MenuItem>
                ))
              )}


            </MenuList>
          </Menu>
        </Flex>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size={'sm'} name={user.username} />


              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        { children }
      </Box>
    </Box>
  )
}

export default SidebarWithHeader

