import { FiCompass, FiHome, FiSettings, FiStar, FiTrendingUp } from 'react-icons/fi';

export const LinkItems = [
  {
    name: 'Admin Fleet',
    icon: FiCompass,
    url: '/dashboard/admin/fleet',
    user_permissions: [ 'dd_admin' ],
    location_permissions: ['admin']
  },
  {
    name: 'Fleet',
    icon: FiCompass,
    url: '/dashboard/fleet',
    user_permissions: ['dd_admin','company_owner'],
    location_permissions: ['admin']
  },
  
]

export const LinkItemsOld = [
  {
    name: 'Home',
    icon: FiHome,
    url: '/dashboard',
    user_permissions: ['dd_admin', 'company_owner' , 'company_member'],
    location_permissions: ['admin']
  },
  {
    name: 'Admin Fleet',
    icon: FiCompass,
    url: '/dashboard/admin/fleet',
    user_permissions: [ 'dd_admin' ],
    location_permissions: ['admin']
  },
  {
    name: 'Fleet',
    icon: FiCompass,
    url: '/dashboard/fleet',
    user_permissions: ['company_owner'],
    location_permissions: ['admin']
  },

  {
    name: 'Reports',
    icon: FiStar, subItems: [
      {
        name: 'Fleet',
        icon: FiCompass,
        url: '/dashboard/fleet',
        user_permissions: ['company_owner'],
        location_permissions: ['admin']
      },
      {
        name: 'Trucks',
        icon: FiCompass,
        url: '/dashboard/truck-summary',
        user_permissions: [],
        location_permissions: ['admin']
      },
      {
        name: 'Admin Fleet',
        icon: FiCompass,
        url: '/dashboard/admin/fleet',
        user_permissions: [ 'dd_admin' ],
        location_permissions: ['admin']
      }
    ]},

]