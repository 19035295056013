import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner, filter,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // or 'ag-theme-material.css';
import 'ag-grid-enterprise';
import grab from '../../utils/grab'
import { CalendarIcon } from '@chakra-ui/icons';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Papa from 'papaparse';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


function FleetReport() {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedAccountData, setSelectedAccountData] = useState([]);
  const [showAccountGrid, setShowAccountGrid] = useState(true);
  const [searching, setSearhing] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const current = moment.utc().startOf('day').toDate();
  const nextDay = moment.utc(current).add(1, 'days').toDate();
  const [visibleRowCount, setVisibleRowCount] = useState(0);




  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(nextDay);

  const gridApi = useRef(null);
  const gridRef = useRef(null);
  const saveTimeoutRef = useRef(null);


  useEffect(() => {
    let filterState = localStorage.getItem('filterState');
    setShowFilters(filterState === 'true');
    fetchAccountDetails();
    window.addEventListener('resize', () => {
      //gridApi.current && gridApi.current.sizeColumnsToFit();
    });
  }, []);

  useEffect(() => {
    //gridApi.current && gridApi.current.sizeColumnsToFit();
  }, [selectedAccountData, showAccountGrid, showFilters]);


  const fetchAccountDetails = async (accountNum) => {
    try {
      setSearhing(true)
      const start = startDate.toISOString();
      const end = endDate.toISOString();
      const response = await grab.get(`/admin/fleet/`, {
        params: {
          startDate: start,
          endDate: end,
        },
      });
      console.log(`Fetched ${response.data.length} fleet records.`);
      setSelectedAccountData(response.data);
      setVisibleRowCount(response.data.length);
      setSearhing(false)
    } catch (error) {
      console.error('Error fetching account details:', error);
      setSearhing(false)
    }
  };

  const handleSearch = () => {
    fetchAccountDetails();
  };


  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Company", field: "CompanyName", enableRowGroup: true},
    { headerName: "Account", field: "AccountNum", enableRowGroup: true },
    { headerName: "Location", field: "Location", enableRowGroup: true },
    { headerName: "Truck Number", field: "TruckNum", enableRowGroup: true },
    { headerName: "Sale Number", field: "SaleNumber", enableRowGroup: true },
    {
      headerName: "Volume",
      field: "Volume",
      aggFunc: 'sum',
      footerValueGetter: 'totalVolume',
      valueFormatter: params => parseFloat(params.value).toFixed(2) // Format values to 2 decimal places
    },
    {
      headerName: "Running Total",
      field: "RunningTotal",
      enableRowGroup: true,
      valueGetter: params => {
        if (!params.node.group) {
          // no need to handle group levels - calculated in the 'ratioAggFunc'
          return {
            SaleNumber: params.data.SaleNumber,
            TSDate: params.data.TSDate,
            RunningTotal: params.data.RunningTotal,
            toString: () => params.data.RunningTotal,
          }
        }
      },
      aggFunc: params => {
        let maxTSDate = null;
        let maxTSDateRunningTotal = null;
        console.log(params.values)

        // Iterate through all values to find the highest TSDate and its RunningTotal
        params.values.forEach(value => {
          if (maxTSDate === null || new Date(value.TSDate) > new Date(maxTSDate)) {
            maxTSDate = value.TSDate;
            maxTSDateRunningTotal = value.RunningTotal;
          }
        });

        // Return the RunningTotal of the row with the highest TSDate
        return maxTSDateRunningTotal ? maxTSDateRunningTotal : '';
      },
      valueFormatter: params => parseFloat(params.value).toFixed(2), // Format values to 2 decimal places
      //footerValueGetter: lastRowRunningTotalValueGetter // Use the custom footer value getter
    },
    { headerName: "VIN", field: "VIN", enableRowGroup: true },
    { headerName: "AKA", field: "AKA", enableRowGroup: true },
    { headerName: "Transaction Number", field: "TransactionNumber", enableRowGroup: true },
    { headerName: "Time", field: "TSDate", enableRowGroup: true },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { 'textAlign': 'left' },
      menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      filter: true,
      sortable: true,
      resizable: true,
      enableRowGroup: true,
      width:200
    }
  }, []);

  const handleHideFilter = () => {
    const status = !showFilters;
    console.log(status);
    setShowFilters(status);
    localStorage.setItem('filterState', JSON.stringify(status));
  };

  const saveGridState = () => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current); // Clear existing timeout if present
    }

    // Set a new timeout to save the grid state after 2 seconds
    saveTimeoutRef.current = setTimeout(() => {
      if (gridRef.current) {
        const columnState = gridRef.current.columnApi.getColumnState();
        const columnGroupState = gridRef.current.columnApi.getColumnGroupState();
        const filterState = gridApi.current.getFilterModel();
        const gridState = { columnState, columnGroupState, filterState };
        localStorage.setItem('adminGridState', JSON.stringify(gridState));
        console.log('grid saved');
      } else {
        console.warn('getColumnState is not a function');
      }
    }, 1000);
  };


  // Load the grid's saved state
  const loadGridState = () => {
    const savedState = localStorage.getItem('adminGridState');
    if (savedState) {
      const gridState = JSON.parse(savedState);
      console.log('loading state')
      gridRef.current.columnApi.applyColumnState({ state: gridState.columnState, applyOrder: true});
      gridRef.current.columnApi.setColumnGroupState(gridState.columnGroupState);
      gridApi.current.setFilterModel(gridState.filterState);
      console.log('loading stateeee')
    }
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    loadGridState(); // Load the saved grid state
  };

  const updateVisibleRowCount = () => {
    setVisibleRowCount(gridApi.current.getDisplayedRowCount());
  };

  const DateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <InputGroup>
    <InputLeftElement
      pointerEvents="none"
      children={<CalendarIcon color="gray.400" />}
    />
    <Input
      onClick={onClick}
      value={value}
      ref={ref}
      onChange={onChange}
      placeholder="Select date"
    />
  </InputGroup>
  ));
  
  
  return (
    <Box width={'100%'} height={'100%'}>
      <Grid templateColumns={showFilters ? { base: '1fr', md: '8fr 2fr' } : { base: '1fr' }} gap={3} minH="100vh">
        <Card width={"100%"} height={'100%'} minH='500px'>
          <CardHeader>
            <HStack justify={'space-between'}>
              <Text fontSize="xl" fontWeight="bold">
                Fleet Report
              </Text>
              <Button onClick={handleHideFilter} colorScheme="blue" mt={3}>
                  {showFilters ? 'Hide Filter' : 'Show Filter'}
              </Button>

            </HStack>
            <Text fontWeight={'light'} color={'#a1a1a1'}>Records: {visibleRowCount}</Text>


          </CardHeader>
          <CardBody>
          <Box className="ag-theme-alpine" width={"100%"} height={580}>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={selectedAccountData}
                defaultColDef={defaultColDef}
                onFilterChanged={updateVisibleRowCount}
                onToolPanelVisibleChanged={saveGridState}
                onColumnVisible={saveGridState} // When a column is shown/hidden
                onColumnPinned={saveGridState}   // When a column is pinned/unpinned
                onColumnMoved={saveGridState}
                onColumnResized={saveGridState}
                onColumnRowGroupChanged={saveGridState}
                sideBar={{
                  toolPanels: [
                    {
                      id: 'columns',
                      labelDefault: 'Columns',
                      labelKey: 'columns',
                      iconKey: 'columns',
                      toolPanel: 'agColumnsToolPanel',
                    },
                    {
                      id: 'filters',
                      labelDefault: 'Filters',
                      labelKey: 'filters',
                      iconKey: 'filter',
                      toolPanel: 'agFiltersToolPanel',
                    },
                    // other tool panels as needed
                  ],
                  defaultToolPanel: 'filters', // This will have the sidebar closed by default
                }}
                groupIncludeFooter={true}
                groupIncludeTotalFooter={true}
                onGridReady={onGridReady}
                rowGroupPanelShow='always'

              />
          </Box>
          </CardBody>
        </Card>
        {showFilters && (
        <VStack spacing={3} align="start"> {/* Wrap the date selection card in a VStack */}

        <Card height="auto">
          <CardHeader>
            <Text fontWeight="bold">
              Select Date Range
            </Text>
          </CardHeader>
          <CardBody>
              <FormLabel>Start Date</FormLabel>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                w="30%"
                customInput={<DateInput />}
              />
              <FormLabel mt={2} >End Date</FormLabel>
              <DatePicker
                selected={endDate}
                customInput={<DateInput />}
                onChange={(date) => setEndDate(date)}
              />
              <Button onClick={handleSearch} colorScheme="blue" w={'40%'} mt={3}>
              { searching ? <Spinner size={'sm'}/> : 'Search'}
              </Button>
          </CardBody>
        </Card>
        
        </VStack>
        )}
      </Grid>
    </Box>
  );
}

export default FleetReport;
