import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Spinner, VStack, Center } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import grab from '../../utils/grab';
import { useAtom } from 'jotai/index';
import { userAtom } from '../../atoms';



const ProtectedWrapper = ({element}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = useAtom(userAtom);

  const fetchUserData = async () => {
    try {
      const response = await grab.get('/user');
      console.log(response.data)
      setUser(response.data);const accessToken = Cookies.get('accessToken');
      const refreshToken = Cookies.get('refreshToken');
      const deviceId = Cookies.get('deviceId');
      if (!(accessToken && refreshToken && deviceId)) {
        navigate('/auth/sign-in');
      } else {
        // Check if the user is trying to access an admin route without admin permissions
        if (window.location.pathname.startsWith('/dashboard/admin') && response.data.user_permission !== 'dd_admin') {
          navigate('/dashboard'); // Redirect to dashboard or some other non-admin page
          return;
        }
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/auth/sign-in');
      }
    }
  };

  useEffect(() => {
    fetchUserData()
  }, [navigate]);

  if (isLoading) {
    return null
  }
  return element;
};

export default ProtectedWrapper;
